.dowody{
    padding: 30px 0;
    &_tytul > p {
        margin-bottom: 0;
    }
    &_tekst{
        color: #13100D;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        b, strong{
            font-weight: bold;
        }
    } 
    .row {
        align-items: center;
    } 
    &_img {
        text-align: center;
    }
    .big-title {
        font-size: 40px;
    } 
    .grey-tekxt {
        font-size: 20px; 
    } 
}
.ref{
    .container {
        padding: 20px 0;
    }
    .row{
        align-items: center;
        justify-content: space-around;
    }
    .row-reverse {
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        justify-content: space-around;
    }
    &_txt{
        color: #24221F;
        font-family: Poppins;
        font-size: 16px;
        line-height: 24px;
      
    }
    &_obrazek{
        height: 414px;
        width: 333px;
        object-fit: scale-down;
    }
    .center{
        text-align: center;
    }
}
.referencje-revers{
    position: relative;
    min-height: 414px;
    &::before{
        background-color: #F3F3F3;
        content: '';
        position: absolute;
        width: 574px;
        height: 100%;
        right: 0;
        z-index: -1;
    }
}
.referencje-pod{
    position: relative;
    min-height: 414px;
    &::before{
        background-color: #F3F3F3;
        content: '';
        position: absolute;
        width: 574px;
        height: 100%;
        left: 0;
        z-index: -1;
    }
}
.white-bg{
    background-color: white;
    padding: 70px 0;
    .kontakt_napis {
        color: #8CC63F;
        font-family: Poppins;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        margin-bottom: 0; 
        margin-bottom: 20px;
      }
}
@media screen and (max-width:1300px){
    .referencje-pod::before {
        width: 218px;
    }
    .referencje-revers::before {
        width: 218px;
    }
}
@media screen and (max-width:991px){
    .referencje-pod::before {
        width: 100%;
    }
    .referencje-revers::before {
        display: none;
    }
    .ref .row-reverse {
        flex-flow: column;
    }
    .ref_txt {
        padding-top: 10px;
    }
}
@media screen and (max-width:768px){
    .reverse .row {
        flex-flow: column-reverse;
        display: flex;
    }
    .ref{
        &_obrazek {
        width: 100%;
        }
        &_txt {
            font-size: 15px;
            padding: 0 20px;
        }
        .row-reverse {
            flex-flow: column;
        }
        .container {
            padding: 0; 
        }
        .container {
            padding: 0; 
        }
        &_obrazek {
            height: 314px;
        }
    }
    .white-bg{
        .col-md-5{
            text-align: center;
        }
    }
}