.top-slider {
  width: 100%;
  position: relative;
  .owl-dots {
    position: absolute;
    width: 100%;
    bottom: 20px;
    margin-top: 0;
    .owl-dot span {
      margin: 3px;
      background-color: white;
    }
    .owl-dot.active span, .owl-dot:hover span {
      background-color: white;
      padding: 2px;
      background-clip: content-box;
      border: 1px solid white;
    }
  }
}

.top-slider__slide {
  width: 100%;
}

.top-slider__content {
  padding: 150px 15px 90px 15px;
  text-align: center;
  color: white;
}
