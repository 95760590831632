.post{
    padding: 20px 0;
    &_powrot{
        height: 19px;
        width: 43px;
        display: inline-block;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &_baner{
        position: relative;
    }
}
.post-design{
    p{
        margin: 10px 0;
    }
    &_img{
    height: 300px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    padding: 0;
    }
    &_layer {
        height: 100%;
        position: absolute;
        width: 100%;
        background-color: rgba(0,0,0,0.4);
        z-index: 0;
    }
    &_tekst-tyt {
        z-index: 5;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    &_title {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 36px;
        line-height: 30px;
        font-weight: 400;
        padding-left: 110px;
        b, strong{
            font-weight: bold;
        }
    }
    &_miejsce-wykonania {
        color: white;
        z-index: 1;
        font-size: 20px;
        font-weight: bold;
        padding-left: 110px;
      }   
       
}
#ytplayer > p {
    height: 360px;
   }
   
.post-content{
    margin: 70px 0;
    &_tekst{
        color: #13100D;
        font-family: Poppins;
        font-size: 22px;
        font-weight: 200;
        padding-right: 60px !important;
        line-height: 34px;
        font-weight: 200;
        padding: 30px 0px;
        b, strong{
            font-weight: bold;
            font-size: 24px;
        }
    }
    &_film{
        margin: 30px 0px;
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    
}
.post-gallery{
    padding-bottom: 50px;
    &_item{
        width: 100%;
    }
}
#primary {
    display: none;
}
#secondary {
    display: none;
}
   
@media screen and (max-width: 768px){
    .post-content_tekst {
        padding-left: 30px;
      }
}