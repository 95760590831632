footer {
  padding: 30px 0;
  background-color: #13100D;
  
}
.footer__credentials {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: 30px;
  p {
    margin: 0 0 5px;
    transition: $transition;
  }
  a:hover {
    color: $grey-mid;
  }

}
.footer_gdzie {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;
}
.footer_adres {
  color: #FFFFFF;
  font-family: Poppins;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  flex-flow: column;
}
.footer_ikonki {
  position: relative;
  top: 70px;
  &>a{
    padding: 0 3px;
    transition: $transition;
    &:hover{
      transform: scale(1.1);
    }
  }
 }
.kolumna-4 {
  text-align: end;
}
.footer_orly > img:nth-child(1) {
  padding-right: 5px;
 }
.seo{
  color: #828282;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  line-height: 30px;
}
@media screen and (max-width:991px){
  .footer__credentials {
    flex-flow: column;
    text-align: center;
    justify-content: center;
  }
  .kolumna-1, .kolumna-2,.kolumna-3,.kolumna-4{
    margin: 0 auto;
    text-align: center;
  }   
  .footer_ikonki {
    top: 0; 
    padding: 20px 0;
  }
  .footer_orly {
    margin-top: 10px;
  }
  .seo-cale{
    text-align: center;
  }
}