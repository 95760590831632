header{
    box-shadow: 0px 10px 11px -8px rgba(0, 0, 0, 0.14);
}
.sub-menu .current_page_item::after {
    bottom: 5px;
    }
.sub-menu .menu-item:hover::after {
    bottom: 5px;
    }
#mainNav {
    display: flex !important;
    flex-flow: column;
    justify-content: end;
}
.header-ikonki {
    display: flex;
    align-items: center;
    margin-left: auto;
    .iko {
        width: 24px;
        height: 24px;
        transition: $transition;
        &:hover{
            transform: scale(1.1);
        }
    }
    &_linki {
        padding-top: 6px;
    }
}
.wpml-ls-legacy-list-horizontal {
    border:1px solid transparent;
    padding:7px;
    clear:both;
    color:  #E0E0E0;
    font-family: $glob-font;
    font-size: 14px;
    font-weight: 800;
    &>ul{
        display: flex;
        flex-flow: row-reverse;
    }
    transition: $transition;
    .wpml-ls-link:hover{
        color:#36282B;
    }
}
.wpml-ls-current-language{
    color: #36282B;
}
.wpml-ls-slot-shortcode_actions.wpml-ls-item.wpml-ls-item-en.wpml-ls-first-item.wpml-ls-item-legacy-list-horizontal {
    border-left: 2px solid #E0E0E0;
}
.navbar-expand-lg .navbar-nav {
    padding: 20px 0;
    padding-bottom: 32px;
}
.menu-item{
    position: relative;
    transition: $transition;
    &:hover{
        &::after{
            position: absolute;
            content: '';
            height: 2px;
            width: 31px;
            background-color: #13100D;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.current_page_item{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        height: 2px;
        width: 31px;
        background-color: #13100D;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.wycena-menu.current_page_item{
    position: relative;
    &::after{
        position: absolute;
        content: '';
        height: 2px;
        width: 31px;
        background-color: #8CC63F;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
    }
}
.wycena-menu.menu-item{
    position: relative;
    transition: $transition;
    &:hover{
        &::after{
            position: absolute;
            content: '';
            height: 2px;
            width: 31px;
            background-color: #8CC63F;
            bottom: -7px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
}
.dd-projekt{
    position: relative;
    font-size: 0;
    width: 120px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_DD projekt.png');
        width: 92px;
        height: 19px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.ofert-menu{
    position: relative;
    font-size: 0;
    width: 80px;
    height: 17px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_oferta.png');
        width: 52px;
        height: 15px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.klienci-menu{
    position: relative;
    font-size: 0;
    width: 140px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_klienci.png');
        width: 115px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.realizacje-menu{
    position: relative;
    font-size: 0;
    width: 110px;
    &#menu-item-376>a:nth-child(1)::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_realizacje.png');
        width: 81px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    &#menu-item-550>a:nth-child(1)::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_realizacje.png');
        width: 81px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    &:hover{
        .sub-menu {
            display: block;
        }
    }
}
.wycena-menu{
    position: relative;
    font-size: 0;
    width: 150px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_zamow wycene.png');
        width: 128px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.kontakt-menu{
    position: relative;
    font-size: 0;
    width: 90px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_kontakt.png');
        width:65px;
        height: 14px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.dd-projekt-en{
    position: relative;
    font-size: 0;
    width: 120px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/DD projekt en.png');
        width: 92px;
        height: 19px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.ofert-menu-en{
    position: relative;
    font-size: 0;
    width: 80px;
    height: 17px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/offer.png');
        width: 52px;
        height: 15px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.klienci-menu-en{
    position: relative;
    font-size: 0;
    width: 140px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/customers.png');
        width: 115px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.realizacje-menu-en{
    position: relative;
    font-size: 0;
    width: 110px;
    &#menu-item-1102>a:nth-child(1)::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/realizations.png');
        width: 81px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    &#menu-item-550>a:nth-child(1)::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/menu_realizacje.png');
        width: 81px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    &#menu-item-1224>a:nth-child(1)::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/realizations.png');
        width: 92px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    &:hover{
        .sub-menu {
            display: block;
        }
    }
}
.wycena-menu-en{
    position: relative;
    font-size: 0;
    width: 150px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/price.png');
        width: 128px;
        height: 19px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.kontakt-menu-en{
    position: relative;
    font-size: 0;
    width: 90px;
    a::before{
        position: absolute;
        content: '';
        background-image: url('../../assets/img/contact.png');
        width:65px;
        height: 14px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
}
.sub-menu {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 2; 
    color: #13100D;
    font-size: 16px;
    font-weight: bold;
    line-height: 36px;
    text-align: center;
    list-style: none;
    padding-left: 0;
    width: 220px;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 10px;
}
   


@media screen and (max-width:991px){
    .navbar {
        z-index: 20;
    }
    #mainNav {
        text-align: end;
        display: none !important;
        &.show{
            display: flex !important;
            position: absolute;
            width: 100%;
            height: 340px;
            background-color: white;
            top: 100px;
        }
    }
    .dd-projekt {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .ofert-menu {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .klienci-menu {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .realizacje-menu {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .wycena-menu {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .kontakt-menu {
        width: 140px;
        a::before {
            transform: translate(-50%,-50%);
            left: 50% !important;
        }
    }
    .menu-item {
        margin-bottom: 20px;
    }
    .ml-auto,
    .mx-auto {
        margin-left: 0 !important; 
    }
    .sub-menu {
        display: none;
        position: relative;
        box-shadow: 0px 10px 19px -4px rgba(0,0,0,0.28);;
        background-color: white;
        line-height: 9px;
        padding-top: 23px;
    }
    .realizacje-menu{
        &:hover{
            .sub-menu {
                display: flex;
                flex-flow: column;
                position: absolute;
            }
        }
    }
    .realizacje-menu#menu-item-376 > a:nth-child(1)::before {
        transform: translate(-50%,-50%);
        left: 50% !important;
    }
    .realizacje-menu#menu-item-550>a:nth-child(1)::before{
        transform: translate(-50%,-50%);
        left: 50% !important;
    }
    #mainNav {
        height:  350px ;
    }
    .sub-menu .menu-item:hover::after {
        bottom: -5px; }
}     