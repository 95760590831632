.napis-black{
    color: #13100D;
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-weight: 300;
    line-height: 52px;
    text-align: center;
    strong, b{
        font-weight: bold;
    }
}
.apa .col-md-6{
    margin-top: 10px;
    padding: 0 5px;
}
.apart{
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
    &_img {
        background-image: url('http://172.12.128.6/wp-content/uploads/2020/03/lux2392.jpg');
        height: 466px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
       }
       &_title {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 24px;
        line-height: 30px;
        font-weight: 400;
        padding: 0px 30px;
       }
       &_content {
        position: absolute;
        bottom: 0;
        left: 0;
        opacity: 0.8;
        background-color: #000000;
        padding-left: 30px !important;
        padding: 20px 0;
        transition: all 1s ease-in-out;
        height: 156px;
        width: 100%;
        &:hover{
            height: 100%;
            .apart_title {
                position: relative;
                top: 150px;
                &::before{
                    position: absolute;
                    content: '';
                    background-image: url('../../assets/img/strzalka_prawo_b.png');
                    height: 19px;
                    width: 43px;
                    top: 100px;
                    left: 30px;
                }
            }
        }
    }     
}
@media screen and (max-width:1300px){
    .apart_content {
        height: 167px;
    }
    .apart_content:hover .apart_title::before {
        top: 120px;
    }
}
@media screen and (max-width:991px){
    .apart_content:hover .apart_title::before {
        top: 140px;
    }
    .apart_title {
        font-size: 21px;
    }
    .apart_content {
        padding: 20px 10px;
        height: 186px; 
    }
}

@media screen and (max-width:768px){
    .apart_title {
        font-size: 19px;
    }
    .apart_content {
        padding-left: 5px !important;
        padding: 30px 0;
        height: 216px; 
    }
    .apart_content:hover .apart_title::before {
        top: 122px;
    }
}