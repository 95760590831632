.najlepsi{
    padding: 30px 0;
    .guzik_calosc {
        display: flex;
        align-items: center;
    }
   .kontakt-omowmy_tekst{
        b, strong {
            font-weight: bold; 
        }
    }
    .tlo_guzik {
        position: fixed;
        right: 250px;
        top: 240px; }
}
.counter-white {
    background-color: white;
    .count-this {
        color: #13100D !important;
    }
    .counter_teskt {
        color: #13100D !important;
    }
    #counter {
        color: #13100D;
    }
    .counter_wiek {
        color: #13100D;
    }
}
#counter1 {
    font-family: "Open Sans";
    font-size: 70px;
    font-weight: 800;
    line-height: 32px;
    padding-bottom: 20px;
  }
.cechy {
    background-color: #E0E0E0;
    padding: 70px 0;
    &_pasek {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }
    &_punkt {
        text-align: center;
        transition: $transition;
        &:hover{
            .cechy_ikonki{
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
            }
        }
        &>p{
            color: #13100D;
            font-family: Poppins;
            font-size: 19px;
            line-height: 24px;
        }
    }
}
@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }

.historia{
    padding: 30px 0;
    &_pasek {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    &_punkt{
        text-align: center;
        &>p{
            color: #C5C4C4;
            font-family: Poppins;
            font-size: 19px;
            font-weight: bold;
            line-height: 24px;
        }
    }
    &_tekst {
        padding-left: 40px;
    }
}
.opis{
    padding: 30px 0;
    ul{
        padding-left: 20px;
        &>li{
            color: #13100D;
            font-family: Poppins;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
        }
    }
    .kontakt-omowmy_tytul {
        margin-top: 0;
        &>p{
            margin-top: 0;
        }
    }
    img{
        width: 100%;
    }
    
}
@media screen and (max-width:1300px){
    .najlepsi .tlo_guzik {
        position: fixed;
        right: 0px;
        top: 240px; }
    .opis{
        .row {
            align-items: center;
          }
    }
    .historia_pasek {
        align-items: baseline; 
        flex-flow: wrap;
        justify-content: center;
    }
    .historia_punkt {
        text-align: center; 
        padding: 0 20px;
    }
    .historia{
        .row{
            align-items: center;
        }
    }
}
@media screen and (max-width:991px){
    .najlepsi .tlo_guzik {
        position: absolute;
        right: 0;
        top: 180px; 
    }
    .opis{
        .col-lg-5{
            padding-top: 20px;
        }
    }
    .opis-lewy{
        .row{
            flex-flow: column-reverse;
        }
    }
    .cechy_punkt > p {
        color: #13100D;
        font-family: Poppins;
        font-size: 16px;
        line-height: 24px; 
    }
}
@media screen and (max-width:768px){
    .najlepsi .tlo_guzik {
        position: absolute;
        right: 0;
        top: 180px; 
    }
    .najlepsi {
        padding: 30px 0; 
        padding-bottom: 60px;
    }
    .opis{
        img {
            width: 100%;
        }
        .kontakt-omowmy_tytul {
            font-size: 32px;
        }
    }
    .opis-lewy{
        .row{
            flex-flow: column-reverse;
        }
    }
    .kontakt-omowmy_img > img {
        width: 100%;
    }
       
    .historia{
        &_pasek {
            flex-flow: column;
        }
        &_punkt{
            width: 100%;
        }
        &_tekst { 
            padding-left: 0;
        }
    }
    .cechy_pasek {
        align-items: center; 
        flex-flow: column;
    }
}