.tlo{
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    &_napis {
        color: #FFFFFF;
        font-family: $glob-font;
        font-size: 36px;
        font-weight: 300;
        line-height: 52px;
        text-align: center;
        b,strong{
            font-weight: bold;
        }
    }
    &_guzik{
        text-align: end;
    }
    &_napis {
        padding-top: 80px;
    }
}
.tlo_napis {
    padding-top: 80px; 
    padding-bottom: 60px;
    position: relative;
}
.tlo_guzik {
    position: fixed;
    top: 280px;
    right: 240px;
    z-index: 2;
}
   
.wycena-page{
    &_lista{
        padding-top: 60px;
        padding-right: 40px;
        ul{
            list-style: none;
            padding-left: 0;
            margin-bottom: 40px;
            margin-right: 30px;
            li{
                display: flex;
                align-items: center;
            }
        }
    }
    &_ikonka {
        height: 22px;
        width: 22px;
        margin-right: 20px;
        object-fit: contain;
    }
    &_podpunkt {
        color: #13100D;
        font-family: Poppins;
        font-size: 20px;
        line-height: 28px;
        margin: 11px 0;
    }
    &_tytul-wycena {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 36px;
        font-weight: 300;
        line-height: 48px;
        text-align: center;
        strong{
            font-weight: 700;
        }
        p{
            margin-bottom: 0;
        }
    }
    &_tlo{
        background-color: #8CC63F;
    }  
    &_wypelnij {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 23px;
        font-weight: 600;
        line-height: 39px;
        text-align: center;
        margin-top: 7px;
    }
    &_podpunkt-wycena {
        color: #FFFFFF;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        text-align: center;
        padding-bottom: 30px;
        a{
            font-weight: bold;
        }
    }
    &_formularz{
        .nf-form-fields-required {
            display: none;
        }
        .nf-error-msg,
        .ninja-forms-req-symbol {
            display: none;
        }
        #nf-label-field-13 {
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            line-height: 17px;
            a{
                font-weight: bold;
            }
        }
        #nf-label-field-6 {
            color: #FFFFFF;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            line-height: 17px;
            a{
                font-weight: bold;
            }
        }
        #ninja_forms_required_items,
        .nf-field-container {
            clear:both;
            position:relative;
            margin-bottom:11px;
        }
        #nf-label-field-7 {
            display: none;
           }
           #nf-label-field-14 {
            display: none;
          }  
        #nf-field-4 {
            color: #8CC63F;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            background-color: white;
            border-radius: 31px;
            padding: 0 30px;
            border: 1px solid white;
            cursor: pointer;
        }
        #nf-field-11 {
            color: #8CC63F;
            font-family: "Open Sans";
            font-size: 16px;
            font-weight: 600;
            line-height: 22px;
            background-color: white;
            border-radius: 31px;
            padding: 0 30px;
            border: 1px solid white;
            cursor: pointer;
        }
        .label-above .nf-field-element,
        .label-below .nf-field-element {
            margin:0;
            width:100%;
            text-align: end;
        }

        .nf-form-content button:hover,
        .nf-form-content input[type=button]:hover,
        .nf-form-content input[type=submit]:hover {
            background:#8CC63F !important;
            transition:all .5s;
            color: white !important;
        }
        #nf-field-4-wrap > div:nth-child(2) {
            text-align: end;
        } 
        .nf-form-content .list-select-wrap .nf-field-element>div,
        .nf-form-content input:not([type=button]),
        .nf-form-content textarea {
            background:#f7f7f7;
            border:1px solid #c4c4c4;
            border-radius:0;
            box-shadow:none;
            color:#787878;
            transition:all .5s;
            color: #1E1B18;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
        }
        .nf-form-content textarea.ninja-forms-field {
            height:200px;
            color: #1E1B18;
            font-family: Poppins;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
        }    
    }
    &_obrazki {
        text-align: center;
        padding-top: 50px;
    }
}
.wycena-ilustracje{
    padding-top: 40px;
    &_img{
        display: flex;
        padding: 0;
        padding-bottom: 30px;
        img{
            height: 842px;
            width: 100%;
            object-fit: cover;
        }
    }
    &_obraz1 {
        margin-left: 10px;
    }
    &_obraz2 {
        margin-right: 5px;
    }
    &_wlasnosc{
        color: #A5A5A5;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 300;
        line-height: 18px;
        border-top: 2px solid #DAD9D9;
        padding: 35px 0;
    }
}
.wyc-page-kontakt{
    background-color: #F3F3F3;
    padding: 60px 0;
    .row {
        align-items: center;
    }
    .kontakt_napis {
        margin-bottom: 24px; 
        margin-top: 0;
    }
    &_tekst {
        text-align: end;
        color: #13100D;
        font-family: Poppins;
        font-size: 24px;
        font-weight: 300;
        line-height: 36px;
        strong {
            font-weight: bold;
        }
        p {
            margin: 0;
        }  
    }
    .btn {
        font-size: 18px;
    }   
    &_center-btn {
        display: inline-block;
        text-align: center;
        margin-left: 30px;
    }
       
}
@media screen and (max-width: 1300px){
    .wycena-ilustracje_img img {
        width: 50%;
        object-fit: contain;
    }
    .tlo_guzik {
        position: unset;
        top: unset;
        right: unset;
        z-index: 2;
    }
    .tlo_napis {
        padding-bottom: 0;}
}
@media screen and (max-width:991px){
    .wycena-page_lista {
        padding-right: 20px;
    }
    .tlo_guzik {
        position: unset;
        top: unset;
        right: unset;
        z-index: 2;
    }
    .tlo_napis {
        padding-bottom: 0;}
    .wycena-ilustracje{
        &_img {
            flex-flow: column;
            img {
                object-fit: contain;
                width: 100%;
            }
        }
        &_obraz1 {
            margin-left: 0;
        }
    }
    .wyc-page-kontakt_center-btn {
        margin-left: 0;
    }
  
}
@media screen and (max-width:768px){
    .wycena-page_lista {
        padding-top: 10px;
        padding-right: 5px;
        padding-bottom: 40px;
    }
  
  .wycena-ilustracje{
        &_img {
        flex-flow: column;
        img {
            height: auto;
            object-fit: contain;
            width: 100%;
          }
        }
        &_obraz1 {
            margin-left: 0;
        }
        &_wlasnosc {
            padding: 35px 10px;
        }
        &_tekst {
            text-align: center;
            margin-bottom: 10px;
        }
        
    }
    .tlo_guzik {
        position: unset;
        top: unset;
        right: unset;
        z-index: 2;
    }
    .tlo_napis {
        padding-bottom: 0;}
    .wyc-page-kontakt{
        &_center-btn {
            margin-left: 0;
        }
        .col-md-4 {
            text-align: center;
        }
        &_tekst{
            text-align: center;
        }
    }
}