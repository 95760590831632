@keyframes hideAndShow {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes growingWaves {
  from {
    width: 0;
  }
  to {
    width: 175px;
  }
}