.tlo{
    &_center{
        padding: 90px 0 !important;
    }
}

.kontakt-omowmy{
    padding: 30px 0;
    position: relative;
    .tlo_guzik{
        position: absolute;
        right: 0;
        top: -60px;
    }
    &_tytul {
        font-size: 36px;
        line-height: 48px;
        color: #13100d;
        font-family: "Poppins";
        font-weight: 300;
        margin-bottom: 0;
        b,strong {
            font-weight: bold;
        }
    }
    &_podpunkt {
        color: #c5c4c4;
        font-weight: 700;
        font-size: 24px;
        margin-top: 6px;
        font-family: "Poppins";
    }
    &_tekst {
        color: #13100D;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
    }
    &_img {
        text-align: center;
    }
    .row {
        align-items: center;
    }
}
.kontakt-page{
    &_gdzie{
        color: #13100D;
        p{
            margin-top: 0;
            font-weight: 300;
        }
    }
    .nf-form-content label {
        font-size:18px;
        line-height:20px;
        display: none;
       }
       
    .footer_mail-tel {
        margin-bottom: 23px !important;
    }
    .fonts {
        color: #13100D;
        font-family: Poppins;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
        font-weight: 300;
        &>p{
            padding: 0;
            margin: 0;
            padding-bottom: 3px;
        }
        &>b,strong{
            font-weight: bold;
        }
    }
    &_linki {
        display: flex;
        flex-flow: column;
    }
    &_ikonki {
        display: flex;
        flex-flow: column;
    }  
    &_dojazdy {
        margin-top: 20px;
    }
    &_kosztorys{
        margin-top: 42px;
    }
    &_ikonki {
        line-height: 41px !important;
        padding: 20px 0px;
    }
    &_fb{
        position: relative;
        padding-left: 40px;
        &::before{
            background-image: url('../../assets/img/kontakt/facebook.png');
            position: absolute;
            content: '';
            height: 30px;
            width: 30px;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_insta{
        position: relative;
        padding-left: 40px;
        &::before{
            background-image: url('../../assets/img/kontakt/insta2.png');
            position: absolute;
            content: '';
            height: 30px;
            width: 30px;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_yt{
        position: relative;
        padding-left: 40px;
        &::before{
            background-image: url('../../assets/img/kontakt/youtube2.png');
            position: absolute;
            content: '';
            height: 30px;
            width: 30px;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: contain !important;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &_info {
        margin-top: 45px !important;
    }
    &_jezyk {
        line-height: 10px !important;
        margin-top: 50px !important;
    }
    .nf-form-fields-required {
        display: none;
    }
    .nf-error-msg,
    .ninja-forms-req-symbol {
        display: none;
    }
    .nf-form-content .list-select-wrap .nf-field-element>div,
    .nf-form-content input:not([type=button]),
    .nf-form-content textarea {
        background:white !important;
        border-radius:0;
        box-shadow:none;
        color:#787878 !important;
        transition:all .5s;
        border: 1px solid #959595 !important;
        color: #1E1B18 !important;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
    }
    #nf-label-field-13{
        color: #1E1B18;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 300;
        line-height: 17px;
        a{
            font-weight: bold;
        }
    }
    .nf-form-content button,
    .nf-form-content input[type=button],
    .nf-form-content input[type=submit] {
        background:#8CC63F;
        border:0;
        color:#f7f7f7;
        transition:all .5s;
        padding: 10px 40px;
        border-radius: 42px;
        border: 1px solid #8CC63F;
        cursor: pointer;
    }
    .nf-form-content button:hover,
    .nf-form-content input[type=button]:hover,
    .nf-form-content input[type=submit]:hover {
        background:white;
        color:#8CC63F;
        transition:all .5s
    }
    #ninja_forms_required_items,
    .nf-field-container {
        margin-bottom:8px;
    }

}
#map{
    height: 500px;
    .marker{
        background-image: url('../../assets/img/kontakt/local.png');
        height: 58px;
        width: 45px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: contain !important;
    }
}   

@media screen and (max-width:768px){
    .kontakt-omowmy{
        .tlo_guzik {
            text-align: center; 
        }
        &_img > img {
            width: unset !important; }
    }
    #map {
        height: 390px;
    }
}