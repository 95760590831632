.twenty{
    .owl-carousel .owl-nav {
        position: absolute;
        margin-top: 0;
        z-index: 1;
        width: 90%; 
        left: 50%;
        transform: translateX(-50%);
    }
    .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
        color: inherit;
        padding: 0 !important;
        font: inherit; 
        background-color: rgba(255,255,255,0.7);
        border: none !important;
        width: 70px;
        height: 46px;
    }
    .owl-carousel .owl-nav button {
        border-radius: unset !important;
    } 
    .owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:focus {
        background: unset !important; }

}

.kafelki-karu{
    .owl-item.active.center{
        .apart_content{
            height: 100%;
            transition: unset !important;
            display: block;
        }
        .apart_title {
            position: relative;
            top: 150px;
            &::before{
                position: absolute;
                content: '';
                background-image: url('../../assets/img/strzalka_prawo_b.png');
                height: 19px;
                width: 43px;
                top: 110px;
                left: 30px;
            }
        }
    }
    .owl-dots{
        visibility: hidden;
    }
    .owl-item{
        .apart_content {
            display: none;
        }
    }
    .owl-carousel .owl-nav {
        position: absolute;
        margin-top: 0;
        z-index: 1;
        width: 90%; 
        left: 50%;
        transform: translateX(-50%);
    }
    .owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
        color: inherit;
        padding: 0 !important;
        font: inherit; 
        background-color: rgba(255,255,255,0.7);
        border: none !important;
        width: 70px;
        height: 46px;
    }
    .owl-carousel .owl-nav button {
        border-radius: unset !important;
    } 
    .owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:focus {
        background: unset !important; }
}
.krakow{
    margin: 50px 0;
    div.col-md-7:nth-child(1) > div:nth-child(2) {
        padding-right: 70px;
       }
       
    .row {
        align-items: flex-end;
      }
    .row-center{
        align-items: start;
    }
    &_tytul{
        color: #13100D;
        font-family: Poppins;
        font-size: 36px;
        font-weight: 300;
        line-height: 44px;
        margin-top: 0;
        margin-bottom: 0;
        strong, b{
            font-weight: bold;
        }
    }
    &_tekst{
        color: #13100D;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        strong, b{
            font-weight: bold;
        }
    }
    .kontakt_napis {
        margin-bottom: 40px; 
    }
    .button {
        margin-top: 70px;
       }
    .padding-left-50{
        padding-left: 50px;
    }   
}