.projekty-dom_tytul{
    color: #13100D;
    font-family: Poppins;
    font-size: 36px;
    font-weight: 300;
    line-height: 44px;
    b,strong{
        font-weight: bold;
    }
}
.projekty-dom{
    color: #13100D;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
}
.pro{
    padding: 40px 0;
}

.projekty-row1 .projekty-dom_obrazek{
    height: 256px;
}
.projekty-row2 {
    .projekty-dom_obrazek{
        height: 394px;
        width: 100%;
    }
    .projekty-dom_tytul{
        margin-top: 0;
    }
}
.projekty-row3 {
    .projekty-dom_obrazek{
        height: 500px;
    }
    .projekty-dom_tytul{
        margin-top: 0;
    }
}
.end{
    text-align: end;
}
.padding-left{
    padding-left: 35px;
}
.none-border{
   .wycena-ilustracje_wlasnosc{
       border-top: none;
   } 
}

@media screen and (max-width:768px){
    .projekty-row1 .projekty-dom_obrazek {
        height: auto;
        width: 100%;
      }
      .projekty-row2 .projekty-dom_obrazek {
        height: auto; 
        width: 100%;
      }
      .projekty-row3 .projekty-dom_obrazek {
        height: auto;
        width: 100%;
      }
}