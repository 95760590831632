.news {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  &:hover {
    .news__img {
      box-shadow: $shadow-short-mid;
    }
    .news__content {
      box-shadow: $shadow-short-mid;
    }
  }
}
.news__link {
  display: flex;
  flex-direction: column;
}
.news__img {
  background-color: $grey;
  height: 220px;
  background-size: cover;
  background-position: center;
  width: 100%;
  transition: $transition;
  box-shadow: $shadow-short-light;
}
.news__content {
  flex: 1;
  @include font-size(1);
  background-color: white;
  padding: 25px;
  box-shadow: $shadow-short-light;
  transition: $transition;
}
.news__title {
  @include font-size(1.8);
  line-height: 25px;
  color: $grey-dark;
  margin: 0;
}
.news__excerpt {
  @include font-size(1.4);
  line-height: 22px;
  color: $grey-mid;
  margin-bottom: 0;
  letter-spacing: 0.4px;
  object-fit: contain;
}
.news__read-more {
  @include font-size(1.4);
  margin-top: 0;
  color: $grey-dark;
  font-weight: 700;
  transition: $transition;
  &:hover {
    color: $grey-mid;
  }
}

@media screen and (min-width: 1200px) {
  .slider {
    .container {
      max-width: 1170px;
    }
  }
}
