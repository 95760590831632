// -----------------------------------------------------------------------------
// This file contains styles that are specific to the home page.
// -----------------------------------------------------------------------------
.black-thin-title{
  color: #13100D;	
  font-family: $glob-font;	
  font-size: 16px;	
  font-weight: 600;	
  line-height: 46px;
}
.big-title{
  font-size: 48px;
  line-height: 48px;
  color: #1a1815;
  font-family: $glob-font;
  font-weight: 300;
  strong{
    font-weight: 800 !important;
  }
}
.grey-tekxt{
  color: #c5c4c4;
  font-family: $glob-font;
  font-size: 24px;
  font-weight: 600;
}
.btn {
  background-color: #8CC63F;
  padding: 10px 21px;
  border-radius: 22px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  border: 2px solid #8CC63F;
  transition: $transition;
  &:hover{
    background-color: white;
    color:#8CC63F;
  }
}
 .top-baner{
  top: 770px !important;
 }



.top-slider-section {
  position: relative;
  .main-title {
    padding-top: 80px;
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 100%;
  }
}

.baner{
  position: relative;
  .baner-napis_projekt {
    position: absolute;
    bottom: 110px;
    z-index: 1;
    left: 400px;
    pointer-events: none;
   }
   
  .owl-carousel .owl-item {
    height: 895px;
  }
  &_layer {
    position: absolute;
    left: 50%;
    top: 38%;
    transform: translate(-50%,-50%);
   }
   
  &_tytyul{
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 70px;
    font-weight: bold;
    line-height: 80px;
    text-align: center;
    margin-bottom: 0;
  }
  &_tekst{
    color: #FFFFFF;
    font-family: Poppins;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  &_img {
    height: 895px;
    width: 100%;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
   }
   .owl-dot {
    width: 44px;
    height: 3px;
    background-color: white  !important;
    margin: 10px 10px;
    opacity: 0.5;
    &.active{
      opacity: 1;
    }
  } 
  .owl-dots {
    padding-top: 10px; 
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .owl-carousel .owl-nav {
    z-index: 0;
    justify-content: space-evenly;
    max-width: 100%;
    bottom: -11px;
    top: unset;
  }
  .owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:focus {
      background: unset !important; 
  }
  .owl-carousel .owl-nav button {
    border: unset !important;
    box-shadow: unset !important; 
  }
}

.mystyle{
  img{
  visibility: hidden;
  }
}
.mieszkania{
  padding: 50px 0;
  .row {
    align-items: center;
  }
  &_pod-klucz{
    position: relative;
    padding-left: 50px;
    margin-bottom: 0;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_klucz.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0;
    }
  }
  &_tytul{
    &>p{
      margin-bottom: 10px;
      margin-top: 30px;
    }
  }
  &_cor-robimy {
    margin-top: 0;
  }
  .button {
    padding: 30px 0;
  }
  #video {
    height: 329px;
    width: 525px;
   }
  &_video {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 20px 0;
    a{
      position: absolute;
      width: 100%;
      height: 300px;
    }
    &::before{
      content: '';
      position: absolute;
      height: 55px;	
      width: 55px;
      background-image: url('../../assets/img/ramka.png');
      bottom: -10px;
      left: -24px;
    }
    &::after{
      content: '';
      position: absolute;
      height: 55px;	
      width: 55px;
      background-image: url('../../assets/img/ramka2.png');
      top: -16px;
      right: -24px;
    }
    p{
      height: 300px;
      width: 100%;
    }
    iframe{
      height: 300px;
      width: 100%;
    }
  }
  #myBtn {
    background-color: 
    transparent;
    border: none;
    margin: auto;
  }
  &_video-btn{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } 
   
}
.wnetrza{
  .row{
    display: flex;
    flex-flow: row-reverse;
  }
  &_calosc {
    padding-left: 70px;
  }
  .mieszkania_video::before {
    left: -20px;
    bottom: 0;
  }
  .mieszkania_video::after {
    right: -20px; 
    top: 0;
  }
  .mrg-10{
    margin-right: 10px;
  }
}
.twenty{
  position: relative;
  .button{
    position: absolute;
    bottom: 170px;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
  }
  .twentytwenty-overlay:hover {
    background: unset; 
  }
  .twentytwenty-before-label {
    opacity: 1;
  }

  .twentytwenty-after-label {
    opacity: 1;
  }
  .twentytwenty-before-label:before {
    position: absolute;
    background: unset; 
    color: #1A1815;	
    font-family: $glob-font;
    font-size: 36px;	
    font-weight: 300;	
    line-height: 72px;
    top: 210px;
  }
  .twentytwenty-after-label:before{
    position: absolute;
    background: unset; 
    color: #1A1815;	
    font-family: $glob-font;
    font-size: 36px;	
    font-weight: 700;
    line-height: 72px;
    bottom: 240px;
  }

  .twentytwenty-vertical .twentytwenty-before-label:before, .twentytwenty-after-label:before {
    left: 50%;
    margin-left: 0;
    text-align: center;
    width: unset; 
    transform: translateX(-50%);
  }
}
.referencje{
  .big-title {
    padding-right: 120px;
  }
  &_img {
  text-align: center;
  }
  &_wiadomosc{
    position: relative;
    padding-left: 50px;
    margin-bottom: 0;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_referencje.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0;
    }
  }
  &_tekst {
    color: #363636;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  }
}
.wyroznienie{
  background-color: #E0E0E0;
  padding: 50px 0;
  padding-bottom: 70px;
  .col-12{
    text-align: center;
  }
  &_tytul{
    position: relative;
    padding-left: 50px;
    margin-bottom: 0;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_wyr.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; 
    object-fit: contain;
    height:108px;
  }
  .owl-carousel .owl-nav button {
    border: unset !important;
    box-shadow: unset !important; 
  }
  .owl-carousel .owl-nav button:hover, .owl-carousel .owl-nav button:focus {
    background: unset !important; 
  }
  .owl-carousel .owl-nav .owl-prev {
    margin-left: -70px; 
  }
  .owl-carousel .owl-nav .owl-next {
    margin-right: -70px;
  }
}
.twenty{
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); 
    cursor: pointer;
  }
}
.zamow-wycene{
  .row {
    display: flex;
    flex-flow: row-reverse;
  }
  &_wiadomosc{
    position: relative;
    padding-left: 50px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_wycena.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
}
.counter{
  background-color: #13100D;
  padding: 90px 0;
  .infinity{
    position: relative;
    margin-bottom: 50px;
    &::before{
      position: absolute;
      content: '∞';
      font-size: 120px;
      top: -12px;
    }
    &>p{
      visibility: hidden;;
    }
  }
  .count-this{
    color: #979797;
    font-family:  "Open Sans";
    font-size: 70px;
    font-weight: 800;
    line-height: 32px;
    padding-bottom: 20px;
  }
  #counter{
    color: #979797;
    font-family:  "Open Sans";
    font-size: 70px;
    font-weight: 800;
    line-height: 32px;
    padding-bottom: 20px;
  }
  &_wiek{
    color: #979797;
    font-family:  "Open Sans";
    position: relative;
    font-size: 70px;
    font-weight: 800;
    line-height: 32px;
    padding-bottom: 20px;
    & > span {
      font-weight: 600;
      font-size: 78px;
     }
    &::before{
      content:'>';
      position: absolute;
      left: -40px;
    }
     
  }
  .infinity_iko{
    color: #979797;
    font-family:  "Open Sans";
    font-size: 90px;
    font-weight: 800;
    line-height: 32px;
    padding-bottom: 20px;
    &> p {
      padding: 0;
      margin: 0;
      text-align: center;
    }
  }
  &_teskt{
    color: #FFFFFF;	
    font-family: $glob-font;
    font-size: 19px;	
    line-height: 24px;
  }
  &_caly {
    display: flex;
   }
  .col-12{
    display: flex;
    align-items: center;
    justify-content: space-between;
  } 
}

.odbior-osobisty{
  &_wiadomosc{
    position: relative;
    padding-left: 50px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_klucz.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
}
.kafelki{
  .zdj{
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    height: 427px;
    position: relative;
    padding: 0;
  }
  &_hover {
    position: absolute;
    height: 427px;
    width: 100%;
    background-color: black;
    opacity: 80%;
   }
  &_wykanczanie {
    font-size: 24px;
    line-height: 30px;
    color: #ffffff;
    font-family: $glob-font;
    font-weight: 400;
    &>p>strong{
      font-size: 18px !important;
    }
   }
   &_teskt {
    position: absolute;
    top: 50%;
    transform: translate3D(-50%,-50%,0);
    left: 50%;
    width: 100%;
    padding: 0 120px;
   }
   
}
.finansowanie{
  &_img{
    left: -20px;
    position: relative;
    &::before{
      content: '';
      background-color: #f3f3f3;
      position: absolute;
      height: 678px;
      width: 612px;
      left: -100px;
      transform: translateX(-50%);
      top: -178px;
      z-index: -1;
    }
  }
  &_tekst{
    color: #13100D;	
    font-family: $glob-font;
    font-size: 14px;	
    font-weight: bold;	
    line-height: 20px;
  }
  &_logo{
    &>img{
      padding-right: 30px;
    }
  }
  &_wiadomosc{
    position: relative;
    padding-left: 50px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_finansowanie.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
}
.dostawcy{
  .owl-carousel .owl-item img {
    display: block;
    margin: 0 auto;
    -o-object-fit: contain;
       object-fit: contain;
       width: 100%;
       height: 90px;}
  &_tytul{
    position: relative;
    padding-left: 50px;
    margin-bottom: 0;
    text-align: center;
    display: inline-block;
    margin-bottom: 30px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_dostawcy.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
  .owl-stage{
    height: 108px;
    display: flex;
    align-items: center;
  }
}
.kontakt{
  .col-md-5{
    text-align: center;
  }
  &_napis {
    color: #8CC63F;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0;
  }
  &_wiadomosc{
    position: relative;
    padding-left: 50px;
    &::before{
      content: '';
      position: absolute;
      background-image: url('../../assets/img/ikonka_kontakt.png');
      top: 50%;
      transform: translateY(-50%);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 36px;
      width: 36px;
      left: 0px;
    }
  }
}
@media screen and (max-width:1600px){
  .baner-napis {
    bottom: -50%;
  }
  .baner_layer {
    top: 29%;
  }
}
@media screen and (max-width:1300px) {
  .baner-napis {
    bottom: -47%;
    &_txt h1 {
      font-size: 50px;
    }
    &_ikonki {
      justify-content: center;
    }
  }
  .baner_layer {
    top: 32%;
    width: 80%;
  }
  .twenty .button {
    position: absolute;
    bottom: -60px;
  }
  .mieszkania #myVideo {
    width: 100% ; 
  }
  .kafelki_teskt {
    padding: 0 20px; 
  }
  .finansowanie_img{
    &> img {
      width: 100%;
    }
  }
  .kafelki-karu .owl-item.active.center .apart_title::before {
    top: 150px !important;
  }
}

@media screen and (max-width:1100px) and (min-width:991px){
  .wyroznienie{
    .owl-carousel .owl-nav {
    justify-content: space-around;
    top: unset;
    bottom: -60px;
    }
  }
}
@media screen and (max-width:991px){
  .baner .baner-napis_projekt {
    left: 50%;
    transform: translateX(-50%);
  }
  .baner_layer {
    width: 100%;
    top: 25%;
}
  .big-title {
    font-size: 38px; 
  }
  .grey-tekxt {
    font-size: 26px;
  }
  .mieszkania {
    padding: 0;
    #myVideo {
      width: 100%;
    }
  }
  .counter{
    .col-12{
      flex-flow: wrap;
    }
    &_column {
      width: 33%;
      text-align: center;
      margin-bottom: 20px;
    }
    &_infinity{
      width: 33%;
      text-align: center;
    }
    &_coffe {
      width: 33%;
      text-align: center;
    }
  }
  .kafelki{
    &_wykanczanie {
      font-size: 22px;
    }
    &_teskt {
      padding: 0 20px;
    }
  }
  .finansowanie{
    .row{
      flex-flow: column;
    }
    &_img{
      left: unset;
      text-align: center;
      margin-bottom: 10px;
      &::before{
        height: 338px;
        bottom: -10px;
        top: unset;
      }
    }
    .button {
      margin-bottom: 10px;
    }
  }
  .baner .owl-carousel .owl-nav {
    justify-content: space-around;
  }
  .mieszkania_video{
    video{
      width: 100%;
    }
  }
}
@media screen and (max-width:768px){
 
  .baner .baner-napis_projekt {
    left: 50%;
    transform: translateX(-50%);
    object-fit: contain;
  }
  .baner{
    &_img {
    height: 705px;
    }
    &_tytyul {
      font-size: 50px;
      line-height: 60px;
    }
    &_tekst {
      font-size: 22px;
      line-height: 33px;
    }
    .owl-carousel .owl-nav {
    justify-content: space-around;
    bottom: -10px;
    width: 100%;
    @media screen and (max-width:577px){
      top: -90px;
    }
    }
    .owl-carousel .owl-item {
    height: 705px;
    }
    .owl-carousel .owl-nav {
      margin-left: 0;
    }
    .owl-dots {
    bottom: 65px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    }
    .owl-dot {
    width: 34px;
    }
  }
    .mieszkania {
      padding: 0 11px; 
    }
    .big-title {
      font-size: 46px;
    }
    .grey-tekxt {
      font-size: 27px;
    }
    .mieszkania_video{
      &::before {
        display: none;
      }
      &::after {
        display: none;
      }
    }
    .wnetrza{ 
      .row {
      flex-flow: wrap;
      }
      &_calosc {
        padding-left: 5px; 
      }
    }
    .mieszkania.referencje {
      padding-top: 80px;
    }
    .referencje_img {
      text-align: center; 
      padding: 31px 0px;
    }
    .referencje .big-title {
      padding-right: 10px; 
    }
    .zamow-wycene .row {
      flex-flow: wrap; 
    }
    .counter {
      .col-12 {
        flex-flow: wrap;
      }
      &_column{
        text-align: center;
        width: 50%;
        margin-bottom: 20px;
      }
      &_infinity {
        width: 50%;
        text-align: center;
      }
      &_coffe {
        width: 50%;
        text-align: center;
      } 
    } 
    .finansowanie{
      &_img {
        left: 50%;
        position: relative; 
        transform: translateX(-50%);
        margin-bottom: 20px;
        &::before {
          display: none;
        }
        &>img{
          width: 100%;
          height: 280px;
          object-fit: contain;
        }
      }
      &_tekst { 
        margin: 20px 0;
      }
      &_wiadomosc {
        position: relative;
        padding-left: 50px; 
        margin: 20px 0;
      }
    }
    .twenty{
      .owl-nav{
        display: none;
      }
    }
}
@media screen and (max-width:500px){
  .counter {
    background-color: #13100D;
    padding: 90px 50px; 
    display: flex;
    flex-flow: column;
    .col-12 {
      flex-flow: wrap;
    }
    &_column{
      text-align: center;
      width: 100%;
      margin-bottom: 15px;
    }
    &_infinity {
      width: 100%;
      text-align: center;
      margin-bottom: 15px;
    }
    &_coffe {
      width: 100%;
      text-align: center;
    } 
  }
  // .baner-napis_ikonki {
  //   padding-top: unset; 
  //   bottom: -390px;
  // }
  .baner_tytyul {
    font-size: 40px;
    line-height: 44px;}

  .baner_tekst {
    font-size: 20px;
    line-height: 31px;}

  .baner-napis_projekt {
    width: 100%;
    height: 118px;
  }
  .btn {
    padding: 10px 18px;
  }
}
