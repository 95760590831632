// mixin to pixel fallback of rem font size
// -----------------------------------------------------------------------------

$pixelBase : 16;
@mixin font-size($sizeValue: 1.6){
  font-size: ($sizeValue * 10) + px;
  font-size: (($sizeValue * 10) / $pixelBase)  + rem;
}

// Make circle
// ---------------------------------------------------------------------------
@mixin circle($size) {
  border-radius: 50%;
  display: block;
  width: $size;
  height: $size;
}