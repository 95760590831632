.pagination {
  text-align: center;
  margin-bottom: 20px;
}

.prev,
.next {
  border-radius: 50% !important;
  position: relative;
  height: 30px;
  width: 30px;
  background-color: $black !important;
  border: 1px solid $black !important;
  transition: $transition;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  &:focus {
    outline: none;
  }
  &:hover,
  &:focus {
    background: white !important;
    span {
      border-color: $black !important;
    }
  }
  span {
    position: absolute;
    top: 9px;
    left: 10px;
    height: 10px;
    width: 10px;
    border-bottom: 2px solid white;
    border-left: 2px solid white;
  }
}
.prev {
  margin-right: 10px !important;
  span {
    transform: rotate(45deg);
  }
}
.next {
  margin-left: 10px !important;
  span {
    transform: rotate(-135deg);
    left: 7px;
  }
}

//
// ─── PAGINATION ARROW AS IMAGES ──────────────────────────────────────────────────
//

// .page-prev {
//   display: inline-block;
//   background: url("../img/arrow_left.png") no-repeat center center;
//   background-size: contain;
//   height: 15px;
//   width: 15px;
// }

// .page-next {
//   background: url("../img/arrow_right.png") no-repeat center center;
//   background-size: contain;
//   display: inline-block;
//   height: 15px;
//   width: 15px;
// }

.page-numbers {
  display: inline-block;
  padding: 7px 10px;
  margin: 0 1px;
  @include font-size(2);
}
