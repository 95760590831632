/* Colors */
$black: #000;
$white: #fff;

// Grey hues
$grey: #cacaca;
$grey-mid: #838484;
$grey-dark: #404040;

/* Shadow presets */
$shadow-outline:    0px 2px 5px rgba(0, 0, 0, 0.3);
$shadow-short-light:    0px 5px 10px rgba(0, 0, 0, 0.2);
$shadow-short-mid:     0px 5px 10px rgba(0, 0, 0, 0.4);

/* Font used across the document */
$glob-font: 'Poppins', sans-serif;

/* Transition time for news list */
$transition: all 0.2s ease-in-out;
