.row-oferta{
    background-color: #E0E0E0;
    padding: 30px 0;
    &_calosc {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        &> ul {
            display: flex;
            justify-content: space-between;
            width: 80%;
            list-style: none;
            padding-left: 0;
            &> li{
                color: #13100D;
                font-size: 19px;
                line-height: 19px;
                cursor: pointer;
                &:hover{
                    -webkit-text-stroke-width:1px;
                    -webkit-text-stroke-color:black;
                }
            }
        }
    }  
}
.no-be-af{
    &::before{
        display: none;
    }
    &:after{
        display: none;}
}
.oferta-page{
    padding-top: 70px;
    padding-bottom: 60px;
    &_tytul {
        font-size: 36px;
        line-height: 48px;
        color: #13100d;
        font-family: "Poppins";
        font-weight: 300;
        margin-bottom: 0;
        b,strong {
            font-weight: bold;
        }
        p{
            margin: 0;
        }
    }
    &_podpunkt {
        color: #c5c4c4;
        font-weight: 700;
        font-size: 24px;
        margin-top: 6px;
        font-family: "Poppins";
    }
    &_tekst {
        color: #13100D;
        font-family: Poppins;
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        padding-right: 60px;
    }
    #myBtn1 {
        background-color: 
        transparent;
        border: none;
        margin: auto;
      }
      &_video-btn{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      } 
      .mystyle{
          img{
          visibility: hidden;
          }
      }
    &_tekst {
        margin-bottom: 40px;
    } 
}
.remont{
    padding-bottom: 60px;
    .row {
        border-top: 2px solid #DAD9D9;
        padding-top: 90px;
    }
    .col-md-5{
        padding-left: 30px;
    }
    &_img{
        width:97%;
    }
    &_tekst{
        padding-right: 0;
    }

}
.finansowanie_img{
    width: 100%;
    height: 310px;
    object-fit: contain;
}
.margin-p-zero{
    margin: 0;
}
@media screen and (max-width:1300px){
    .remont .row {
        align-items: center;
    }
    .row-oferta_calosc > ul > li {
        font-size: 17px;
    }
}
@media screen and (max-width:991px){
    .remont{
        &_zmiana{
            flex-flow: column-reverse;
        }
    }
    .row-oferta_calosc > ul {
        justify-content: inherit;
        flex-flow: wrap;
    }
}
@media screen and (max-width:768px){
    .remont{
        &_zmiana{
            flex-flow: column-reverse;
        }
    }
    .row-oferta_calosc > ul {
        flex-flow: column;
        text-align: center;
   }
   .row-oferta_calosc {
    flex-flow: column-reverse;
   }
}